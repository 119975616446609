<template>
    <v-card
        width="100%"
        max-width="500"
        class="mb-5 mr-sm-3"
    >
        <v-card-title>{{ cpaNetwork.label }}</v-card-title>
        <template v-if="integration">
            <template v-if="cpaNetwork.integrationType === 'OAuth'">
                <v-card-text>
                    {{ trans('pages.userCpaIntegration.statusText.success') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="red" @click="$emit('delete', integration.id, cpaNetwork.label)">{{ trans('buttons.common.disconnect') }}</v-btn>
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-btn text color="primary" @click="$emit('test', integration.id)">{{ trans('buttons.common.testConnection') }}</v-btn>-->
                </v-card-actions>
            </template>
        </template>
        <template v-else>
            <template v-if="cpaNetwork.integrationType === 'OAuth'">
                <v-card-text>
                    {{ cpaNetwork.translation.text[$store.getters.curlocale].connectDescription }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            text
                            color="primary"
                            :href="cpaNetwork.authUserRoute"
                    >{{ trans('buttons.common.connect') }}</v-btn>
                </v-card-actions>
            </template>
        </template>
    </v-card>
</template>

<script>
    export default {
        name: "CpaNetworkIntegrationCard",
        props: {
            cpaNetwork: {
                type: Object,
                required: true,
            },
            integration: {
                type: [Object],
                default: null,
            }
        },
        components: {
        },
        mixins: [
        ],
        data() {
            return {

            }
        },
        created() {

        },
        watch: {
        },
        methods: {

        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
