<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <v-card
                        width="100%"
                        max-width="600"
                        class="mb-5"
                >
                    <v-card-text>
                        {{ trans('pages.userCpaIntegration.description') }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <div class="d-flex flex-row mb-3 justify-space-around flex-wrap">
            <template v-if="this.cpaNetworks && this.cpaNetworks.length">
                <cpa-network-integration-card
                        v-for="cpaNetwork in this.cpaNetworks"
                        :key="cpaNetwork.id"
                        :cpa-network="cpaNetwork"
                        :integration="integrations[cpaNetwork.id]"
                        v-on:delete="deleteDialogShow"
                ></cpa-network-integration-card>
            </template>
        </div>
        <delete-dialog
                :dialogTrigger="deleteDialog"
                :item-id="deleteItemId"
                :delete-route="deleteRoute"
                @closed="deleteDialogClosed"
                @deleted="reloadUser"
        >
            <template v-slot:title>
                {{ trans('pages.userCpaIntegration.deleteDialogTitle') }}
            </template>
            <template v-slot:text>
                {{ trans('pages.userCpaIntegration.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId}) }}
            </template>
        </delete-dialog>
    </v-container>
</template>

<script>
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import hasOptions from "@/mixins/hasOptions";
    import CpaNetworkIntegrationCard from "@/components/user/cpaNetwork/CpaNetworkIntegrationCard";

    export default {
        name: "CpaNetworkIntegrations",
        components: {
            CpaNetworkIntegrationCard,
            DeleteDialog,
        },
        mixins: [
            deleteDialog,
            hasOptions
        ],
        data() {
            return {
                loading: true,
                cpaNetworks: null,
                integrations: null,

                url: this.route('user.integrations.index'),
                deleteRoute: 'user.integrations.delete',

                editedItem: null,
            }
        },
        created() {
            this.initialize()
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            initialize(){
                this.loading = true;
                axios.get(this.url).then( (response) => {
                    this.cpaNetworks = response.data.cpaNetworks;
                    this.integrations = response.data.integrations;
                }).catch( err => {
                    console.log(err)
                }).then( () => {
                    this.loading = false;
                })
            },
            reloadUser(){
                this.deleteDialogDeleted();
                this.$store.dispatch('getAuth');
            },
        },
        computed: {
        }
    }
</script>

<style scoped>

</style>
